import RequestSender from '@/infrastructure/request-sender';
import { BaseSuperAgentRequestFactory } from '@studyportals/mb-platform-http-requests';
import { UniversitySchedulerClient } from '@studyportals/university-scheduler-interface';

const schedulerUrl = webpackDefinitions.VUE_SCHEDULER_URL;
const thingClient = new UniversitySchedulerClient(
	new BaseSuperAgentRequestFactory(),
	RequestSender,
	schedulerUrl
);

export default thingClient;
