import { UTCDateTime } from '@studyportals/mb-platform-date-time';

class DateDisplayHandler {
	public formatDateAsDisplayableString(date: UTCDateTime): string {
		const day = this.convertDayToOrdinalNumber(date.getDay());
		const month = this.convertMonthNumberToName(date.getMonth());
		const year = date.getFullYear();
		return `${month} ${day} ${year}`;
	}

	private convertMonthNumberToName(monthNumber: number): string {
		switch (monthNumber) {
			case 1:
				return 'January';
			case 2:
				return 'February';
			case 3:
				return 'March';
			case 4:
				return 'April';
			case 5:
				return 'May';
			case 6:
				return 'June';
			case 7:
				return 'July';
			case 8:
				return 'August';
			case 9:
				return 'September';
			case 10:
				return 'October';
			case 11:
				return 'November';
			case 12:
				return 'December';
			default:
				return '';
		}
	}

	private convertDayToOrdinalNumber(dayNumber: number): string {
		let ordinalIndicator = 'th';
		const numberAsString = dayNumber.toString();

		const lastOneNumber = numberAsString.slice(numberAsString.length - 1);
		const lastTwoNumbers = numberAsString.slice(numberAsString.length - 2);

		switch (true) {
			case lastTwoNumbers === '11':
			case lastTwoNumbers === '12':
			case lastTwoNumbers === '13':
				break;
			case lastOneNumber === '1':
				ordinalIndicator = 'st';
				break;
			case lastOneNumber === '2':
				ordinalIndicator = 'nd';
				break;
			case lastOneNumber === '3':
				ordinalIndicator = 'rd';
				break;
		}

		return `${dayNumber}${ordinalIndicator}`;
	}
}

export default new DateDisplayHandler();
