import state from '@/store/state';
import { IMutations } from '@/models/store/state.interface';

const mutations: IMutations = {
	setCampaignId: (id) => {
		state.campaignId = id;
	},
	setScheduledEvents: (events) => {
		state.scheduledEvents = events;
	},
	setStandAloneLogin: (value) => {
		state.standAloneLogin = value;
	},
	setValidationResult: (result) => {
		state.validationResult = result;
	}
};

export default mutations;
