import { createApp, h } from 'vue';
import store from '@/store';
import App from '@/presentation/app.vue';
import LogIn from '@/stand-alone/log-in/log-in.vue';

import Bootstrapper from '@/bootstrapper';

const standAloneLogin = webpackDefinitions.STAND_ALONE_LOGIN === 'true';

import StylesheetLoader from '@/stylesheet-loader';
import { ScheduledCampaignValidationResult } from '@studyportals/university-scheduler-interface';
import { ValidationResponseStatus } from './models/validation-response-status.enum';

const url = webpackDefinitions.VUE_APP_ICONSET_URL;
StylesheetLoader.loadStylesheet(url);

const placeholderSelector = '#UniversitySchedulerOverviewPlaceholder';

const rootElement: HTMLElement | null = document.querySelector(placeholderSelector);
const campaignId: string | undefined = rootElement ? rootElement.dataset.campaignId : '0';

store.mutations.setStandAloneLogin(standAloneLogin);

declare global {
	interface Window {
		schedulerValidationHandler?: {
			response: ScheduledCampaignValidationResult;
			status: ValidationResponseStatus;
		} | null;
	}
}

if (campaignId) {
	store.mutations.setCampaignId(parseInt(campaignId));
}

try {
	Bootstrapper.bootstrap();
	const app = createApp({
		render: () => {
			return h(App);
		}
	});

	app.mount(placeholderSelector);
} catch (error) {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	if (!standAloneLogin || error.name !== 'NotAuthorizedException') {
		throw error;
	}

	const app = createApp({
		render: () => {
			return h(LogIn);
		}
	});

	app.mount(placeholderSelector);
}
