import UserSession from '@/infrastructure/user-session';

export default class Bootstrapper {
	public static bootstrap(): void {
		if (UserSession.isValid()) {
			UserSession.keepAlive();
		} else {
			const error = new Error('No valid user session found.');

			error.name = 'NotAuthorizedException';
			throw error;
		}
	}
}
