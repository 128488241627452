import { defineComponent, onMounted, onUnmounted } from 'vue';
import ScheduledItemsOverviewComponent from '@/presentation/components/scheduled-items-overview/scheduled-items-overview-class';

export default defineComponent({
	setup: () => {
		const component = new ScheduledItemsOverviewComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			hasRetrievalCompleted: component.hasRetrievalCompleted,
			campaignIsRelevantForScheduler: component.campaignIsRelevantForScheduler,
			validatedScheduledItems: component.validatedScheduledItems
		};
	}
});
