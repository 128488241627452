<template>
	<div id="UniversitySchedulerOverview">
		<ScheduledItemsOverview />

		<LogOut v-if="standAloneLogin" />
	</div>
</template>

<script lang="ts" src="./app.ts"></script>
<style lang="scss" src="./styles/main.scss"></style>
