import {
	UserSessionCookieManager, CognitoAuthenticationServicesProvider,
	ISuccessfulAuthenticationResult, IFailedAuthenticationResult, SuccessfulAuthenticationResult
} from '@studyportals/sp-hs-misc/bin';
import { IUserPoolData } from '@/models/user-pool-data.interface';

export default class SingleSignOnSessionManager {
	private userPoolData: IUserPoolData = {
		clientId: webpackDefinitions.VUE_APP_COGNITO_CLIENT_ID,
		poolId: webpackDefinitions.VUE_APP_COGNITO_USER_POOL_ID
	};
	private PR_ENVIRONMENT_DOMAIN = 'studyportals.fyi';
	private cookieDomain = window.location.host.includes(this.PR_ENVIRONMENT_DOMAIN)
		? this.PR_ENVIRONMENT_DOMAIN
		: webpackDefinitions.VUE_APP_DOMAIN;

	// Temporary hardcoded for dev and PR environment.
	private password = 'test1234';
	private authenticationServiceProvider: CognitoAuthenticationServicesProvider;

	public constructor() {
		this.authenticationServiceProvider = new CognitoAuthenticationServicesProvider(
			this.userPoolData.poolId,
			this.userPoolData.clientId
		);
	}

	public async loginInternal(): Promise<void> {
		// Email for internal account (Anastasiia Koshevarova).
		const email = 'testing+02347070788bc0e17d7f3b1304ef20df2797@studyportals.com';
		const authenticationResult = await this.authenticationServiceProvider.authenticate(email, this.password);

		if (!this.authenticationSucceeded(authenticationResult)) {
			throw new Error('Something went wrong during authentication');
		}

		this.setupFromSuccessfulAuthenticationAndKeepAlive(authenticationResult as ISuccessfulAuthenticationResult);

		location.reload();
	}

	public logout(): void {
		const cookieNames = [
			'hs-sso-token-id',
			'hs-sso-token-id-av',
			'hs-sso-token-id-crt',
			'hs-sso-token-refresh',
			'hs-sso-usr'
		];
		// Create a date string for one hour ago (clearly expired).
		const expiredDate = new Date(Date.now() - 3600000).toUTCString();

		cookieNames.forEach((name) => (document.cookie = `${name}=; expires=${expiredDate}; path=/; domain=.${this.cookieDomain};`));

		location.reload();
	}

	private authenticationSucceeded(authResult: ISuccessfulAuthenticationResult | IFailedAuthenticationResult): boolean {
		// eslint-disable-next-line no-underscore-dangle
		return authResult instanceof SuccessfulAuthenticationResult && authResult.__i_successful_authentication_result__ === true;
	}

	private setupFromSuccessfulAuthenticationAndKeepAlive(authResult: ISuccessfulAuthenticationResult): void {
		const userSessionManager = new UserSessionCookieManager(
			this.userPoolData.clientId,
			this.userPoolData.poolId,
			this.cookieDomain
		);
		userSessionManager.setupFromSuccessfulAuthenticationAndKeepAlive(authResult);
	}
}
