import state from '@/store/state';
import { IGetters } from '@/models/store/state.interface';

const getters: IGetters = {
	campaignId: () => {
		return state.campaignId;
	},
	scheduledEvents: () => {
		return state.scheduledEvents;
	},
	standAloneLogin: () => {
		return state.standAloneLogin;
	},
	validationResult: () => {
		return state.validationResult;
	}
};

export default getters;
