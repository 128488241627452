<template>
	<div id="ScheduledItemsOverview">
		<template v-if="hasRetrievalCompleted">
			<template v-if="campaignIsRelevantForScheduler">
				<table>
					<tr>
						<th>Type</th>
						<th>Date</th>
						<th>Status</th>
						<th>Valid</th>
						<th class="WideCell">
							Reasons for being invalid
						</th>
					</tr>
					<tr v-for="item in validatedScheduledItems">
						<td>{{ item.type }} date</td>
						<td>{{ item.date }}</td>
						<td>{{ item.status }}</td>
						<td><i :class="item.valid"></i></td>
						<td class="WideCell">
							<ul
								class="InvalidReasonsList"
								v-if="item.reasons.length"
							>
								<li v-for="reason in item.reasons">
									{{ reason }}
								</li>
							</ul>
						</td>
					</tr>
				</table>
			</template>
			<div v-else class="SchedulingOverviewDescription">
				There are no scheduled tasks for this campaign.
				This means that, on the start and end date,
				the campaign will not start or end automatically.
			</div>
		</template>
	</div>
</template>

<script lang="ts" src="./scheduled-items-overview.ts"></script>
<style lang="scss" src="./scheduled-items-overview.scss"></style>
