import { computed } from 'vue';
import { UTCDateTime } from '@studyportals/mb-platform-date-time';
import { ScheduledCampaignEvent, ScheduledCampaignValidationIssue, ScheduledCampaignValidationResult, ScheduledItemStatus }
	from '@studyportals/university-scheduler-interface';
import { IScheduledItemData } from '@/models/scheduled-item-data.interface';
import store from '@/store';
import SchedulerRequestsHandler from '@/services/scheduler-requests-handler';
import DateDisplayHandler from '@/services/date-display-handler';
import { ValidationResponseStatus } from '@/models/validation-response-status.enum';

export default class ScheduledItemsOverviewComponent {
	private validIcon = 'SchedulerIcon Valid lnr-checkmark-circle';
	private invalidIcon = 'SchedulerIcon Invalid lnr-prohibited';
	private boundSetValidationHandlerMethod: () => void;

	public hasRetrievalCompleted = computed((): boolean => {
		return this.validationResult.value !== undefined && this.scheduledEvents.value !== undefined;
	});

	public campaignIsRelevantForScheduler = computed((): boolean => {
		// Campaigns with an id below this number are old campaigns.
		return this.campaignId.value >= 11950;
	});

	public validatedScheduledItems = computed((): IScheduledItemData[] => {
		return [this.validatedScheduledStartItem.value, this.validatedScheduledEndItem.value];
	});

	private campaignId = computed((): number => {
		return store.getters.campaignId();
	});

	private scheduledEvents = computed((): ScheduledCampaignEvent[] | undefined => {
		return store.getters.scheduledEvents();
	});

	private validationResult = computed((): ScheduledCampaignValidationResult | undefined => {
		return store.getters.validationResult();
	});

	private startDateIssues = computed((): number[] => {
		const issues = this.validationResult.value ? this.validationResult.value.issues : [];
		return issues.filter((issue: ScheduledCampaignValidationIssue) => {
			// Issues in the 200-300 and 500-600 range are related to the start date.
			return (
				issue === ScheduledCampaignValidationIssue.Unknown ||
				issue === ScheduledCampaignValidationIssue.NoEvents ||
				(issue >= 200 && issue < 300) ||
				(issue >= 500 && issue < 600)
			);
		});
	});

	private endDateIssues = computed((): number[] => {
		const issues = this.validationResult.value ? this.validationResult.value.issues : [];
		// Issues in the 400-500 range are related to the end date.
		return issues.filter((issue: ScheduledCampaignValidationIssue) => {
			return (
				issue === ScheduledCampaignValidationIssue.Unknown ||
				issue === ScheduledCampaignValidationIssue.NoEvents ||
				(issue >= 400 && issue < 500)
			);
		});
	});

	private validatedScheduledStartItem = computed((): IScheduledItemData => {
		const allEvents = this.scheduledEvents.value || [];
		const startDateItem = allEvents.find((scheduledEvent) => scheduledEvent.type === ScheduledItemStatus.Start);

		return {
			type: ScheduledItemStatus.Start,
			date: this.scheduledStartDate.value,
			status: startDateItem ? startDateItem.state : '',
			valid: this.startDateIssues.value.length === 0 ? this.validIcon : this.invalidIcon,
			reasons: this.startDateIssues.value.map((issue) => this.translateStartDateIssueIntoSentence(issue))
		};
	});

	private validatedScheduledEndItem = computed((): IScheduledItemData => {
		const allEvents = this.scheduledEvents.value || [];
		const endDateItem = allEvents.find((scheduledEvent) => scheduledEvent.type === ScheduledItemStatus.End);

		return {
			type: ScheduledItemStatus.End,
			date: this.scheduledEndDate.value,
			status: endDateItem ? endDateItem.state : '',
			valid: this.endDateIssues.value.length === 0 ? this.validIcon : this.invalidIcon,
			reasons: this.endDateIssues.value.map((issue) => this.translateEndDateIssueIntoSentence(issue))
		};
	});

	private scheduledStartDate = computed((): string => {
		return this.formatDateForItemStatusAsString(ScheduledItemStatus.Start);
	});

	private scheduledEndDate = computed((): string => {
		return this.formatDateForItemStatusAsString(ScheduledItemStatus.End);
	});

	constructor() {
		this.boundSetValidationHandlerMethod = this.setValidationResultFromEvent.bind(this);
	}

	public mounted(): void {
		if (window.schedulerValidationHandler?.status) {
			this.getValidationResultFromWindow();
		} else {
			this.getValidationResultFromServer();
		}

		void SchedulerRequestsHandler.retrieveSchedulingDetails();
	}

	public unmounted(): void {
		window.removeEventListener('schedulerValidationStatusChanged', this.boundSetValidationHandlerMethod);
	}

	private getValidationResultFromWindow(): void {
		if (window.schedulerValidationHandler?.status === ValidationResponseStatus.PENDING) {
			this.getValidationResultFromEvent();
		} else {
			this.setValidationResultFromEvent();
		}
	}

	private getValidationResultFromEvent(): void {
		// This event expected to dispatch by universityAdmin
		window.addEventListener('schedulerValidationStatusChanged', this.boundSetValidationHandlerMethod);
	}

	private setValidationResultFromEvent(): void {
		if (window.schedulerValidationHandler?.status === ValidationResponseStatus.FINISHED) {
			store.mutations.setValidationResult(window.schedulerValidationHandler.response);
		}
	}

	private getValidationResultFromServer(): void {
		void SchedulerRequestsHandler.retrieveSchedulingValidationResult();
	}

	private formatDateForItemStatusAsString(status: ScheduledItemStatus): string {
		const date = this.determineDateForItemStatus(status);
		if (!date) {
			return '';
		}

		return DateDisplayHandler.formatDateAsDisplayableString(date);
	}

	private determineDateForItemStatus(status: ScheduledItemStatus): UTCDateTime | undefined {
		const allEvents = this.scheduledEvents.value || [];
		const event = allEvents.find((scheduledEvent) => scheduledEvent.type === status);
		if (!event) {
			return;
		}

		/* In the "ScheduledCampaignEvent" interface it is mentioned that "event.date" has the type "UTCDateTime",
		but in the process of sending it over from the back-end it actually becomes a string. That's why a "toString" is used here,
		to both ensure the code works and convince TypeScript that we're working with the right type. */
		return UTCDateTime.fromDateOrISOString(event.date.toString());
	}

	private translateStartDateIssueIntoSentence(issue: ScheduledCampaignValidationIssue): string {
		switch (issue) {
			case ScheduledCampaignValidationIssue.NoEvents:
			case ScheduledCampaignValidationIssue.NoStartEvent:
				return 'Start date not found';
			case ScheduledCampaignValidationIssue.StartEventDateInPastNotExecuted:
			case ScheduledCampaignValidationIssue.StartEventDateTodayNotExecuted:
				return 'Campaign could not be automatically started on start date';
			case ScheduledCampaignValidationIssue.StartEventDateInFutureAlreadyExecuted:
				return 'Campaign was automatically started before start date';
			case ScheduledCampaignValidationIssue.NoEntitiesAttachedToStartEvent:
				return 'No studies or organisations attached to the campaign';
			case ScheduledCampaignValidationIssue.NoTargetedCountriesAttachedToStartEvent:
				return 'Premiumness per country is not set up';
			case ScheduledCampaignValidationIssue.MultipleStartEvents:
				return 'Multiple start dates found';
			case ScheduledCampaignValidationIssue.StartEventDateAfterEndEventDate:
				return 'The start date is after the end date';
			case ScheduledCampaignValidationIssue.NoPricingProducts:
				return 'One or more of the attached entities has no pricing products attached';
			case ScheduledCampaignValidationIssue.UnexpectedPricingProductsCount:
				return 'One or more of the attached entities have an unexpected number of pricing products attached';
			default:
				return 'Invalid for an unknown reason';
		}
	}

	private translateEndDateIssueIntoSentence(issue: ScheduledCampaignValidationIssue): string {
		switch (issue) {
			case ScheduledCampaignValidationIssue.NoEvents:
			case ScheduledCampaignValidationIssue.NoEndEvent:
				return 'End date not found';
			case ScheduledCampaignValidationIssue.EndEventDateInPastNotExecuted:
			case ScheduledCampaignValidationIssue.EndEventDateTodayNotExecuted:
				return 'Campaign could not be automatically ended on end date';
			case ScheduledCampaignValidationIssue.EndEventDateInFutureAlreadyExecuted:
				return 'Campaign was automatically ended before end date';
			case ScheduledCampaignValidationIssue.MultipleEndEvents:
				return 'Multiple end dates found';
			default:
				return 'Invalid for an unknown reason';
		}
	}
}
