import store from '@/store';

import universitySchedulerClient from '@/infrastructure/university-scheduler-client';

class SchedulerRequestsHandler {
	private get campaignId(): number {
		return store.getters.campaignId();
	}

	public async retrieveSchedulingDetails(): Promise<void> {
		const result = await universitySchedulerClient.retrieveScheduledCampaignEvents(this.campaignId);
		store.mutations.setScheduledEvents(result.events);
	}

	public async retrieveSchedulingValidationResult(): Promise<void> {
		const result = await universitySchedulerClient.validateScheduledCampaign(this.campaignId);
		store.mutations.setValidationResult(result);
	}
}

export default new SchedulerRequestsHandler();
