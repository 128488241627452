import { Component, defineComponent } from 'vue';
import AppComponent from '@/presentation/app-class';
import ScheduledItemsOverview from '@/presentation/components/scheduled-items-overview/scheduled-items-overview.vue';
import LogOut from '@/stand-alone/log-out/log-out.vue';

export default defineComponent({
	components: {
		ScheduledItemsOverview: ScheduledItemsOverview as Component,
		LogOut: LogOut as Component
	},
	setup: () => {
		const component = new AppComponent();

		return {
			standAloneLogin: component.standAloneLogin
		};
	}
});
